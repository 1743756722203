import { BrowserRouter, useNavigate } from 'react-router-dom';
import AppRoutes from './routes';
import React, { useState, useEffect } from 'react';
import SplashScreen from './componnents/SplashScreen';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <BrowserRouter>
      <BackButtonHandler>
        <AppRoutes />
      </BackButtonHandler>
    </BrowserRouter>
  );
}

function BackButtonHandler({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate(-1); // Voltar para a página anterior no histórico
    };

    // Adiciona o listener do botão "voltar"
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Remove o listener para evitar múltiplas chamadas
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  return <>{children}</>;
}

export default App;
