import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import logo from '../../images/vitrinneLogo.png';
import logoAlternativo from '../../images/vitrinneLogoAlternative.png';
import baseboardLogo from '../../images/splashScreenLogo.png';
import instagram from '../../images/instagramBaseboard.png';
import Whatsapp from '../../images/whatsappBaseboard.png';
import gmail from '../../images/Gmail.png';
import cidadeImg from '../../images/cidadeImg.png';
import defaultImg from '../../images/defaultImg.png';
import ComercianteDetails from '../../componnents/ComercianteDetails';
import '../../global.css';
import './style.css';

export default function Catalog() {
  const [isMobile] = useState(window.innerWidth <= 768);
  const [comerciantes, setComerciantes] = useState([]);
  const [selectedComerciante, setSelectedComerciante] = useState(null);
  const [modalComercianteDetails , setModalComercianteDetails ] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [cidades, setCidades] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');
  const [modalAberto, setModalAberto] = useState(false);

  const openModalComercianteDetails = (comerciante) => {
    setSelectedComerciante(comerciante);
    setModalComercianteDetails(true);
  }

  const closeModalComercianteDetails = () => {
    setSelectedComerciante(null);
    setModalComercianteDetails(false);
  }

  const fetchCidades = useCallback(async () => {
    try {
      const response = await api.get('/comerciantes/listCidades');
      const cidadesOrdenadas = response.data.sort((a, b) => a.cidade.localeCompare(b.cidade));
      setCidades(cidadesOrdenadas);

      if (!location.state?.cidadeSelecionada) {
        setCidadeSelecionada(cidadesOrdenadas[0]?.cidade || 'Selecione uma cidade');
      }
    } catch (err) {
      console.log(err);
    }
}, [location.state]);

  const fetchDados = useCallback(async () => {
    try {
      const response = await api.get('/comerciantes/listComerciantes');
      const comerciantes = response.data;
      
      const comerciantesAtivos = comerciantes.filter(comerciante => {
        const dataContrato = new Date(comerciante.contrato);
        return comerciante.cidade === cidadeSelecionada && dataContrato > Date.now();
      });

      setComerciantes(comerciantesAtivos);
  
    } catch (err) {
      console.log(err);
    }
  }, [cidadeSelecionada]);

  useEffect(() => {

    if (location.state?.cidadeSelecionada) {
      setCidadeSelecionada(location.state.cidadeSelecionada);
    }
    fetchCidades();
    fetchDados();

    const cidadeSalva = localStorage.getItem('cidadeSelecionada');
    const primeiroAcesso = localStorage.getItem('primeiroAcesso');

    if (!cidadeSalva && primeiroAcesso) {
      setModalAberto(true);
      localStorage.removeItem('primeiroAcesso');
    } else if (cidadeSalva) {
      setCidadeSelecionada(cidadeSalva);
    }
    
    const interval = setInterval(fetchDados, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchDados, location.state, fetchCidades, cidadeSelecionada]);

  useEffect(() => {
    fetchDados();
  }, [fetchDados]);

  const trocarCidade = (cidade) => {
    setCidadeSelecionada(cidade);
    setModalAberto(false); 
    navigate('.', { replace: true, state: { cidadeSelecionada: cidade } });
  };

  const filteredComerciantes = comerciantes.filter((comerciante) =>
    comerciante.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comerciante.servico.toLowerCase().includes(searchTerm.toLowerCase())  
  );

  const abrirWhatsApp = (numero) => {
    const formattedNumber = numero.replace(/\D/g, '');
    const mensagem = encodeURIComponent("Olá, vim do Vitrinne.net e gostaria de saber como posso expor meu negócio lá também!");
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${'55'+formattedNumber}&text=${mensagem}`;
    window.open(whatsappUrl, '_blank'); 
  };

  return (
    <div className='main'>
      {isMobile ? (
        <>
          <div className='header'>
            <img src={logo} alt='logo'/>
          </div>
          <div>
            <Link to="/login">
              <button className='login-button'>
                <span className="material-symbols-outlined notranslate">&#xea77;</span>
              </button>
            </Link> 
          </div>

          <div className='catalog'>
            <div className='cidade'>
              <button className='cidade-button' onClick={() => setModalAberto(true)}>
                <span className="material-symbols-outlined notranslate" style={{ fontSize: '18px' }}>&#xe0c8;</span> 
                <p style={{ color: '#000' }}>{cidadeSelecionada}</p>
                <span className="material-symbols-outlined notranslate" style={{ fontSize: '16px' }}>&#xe3c9;</span>
              </button>
            </div>
            {modalAberto && (
              <div className='modal-cidade'>
                <div className='modal-content-cidade'>
                  <button onClick={() => setModalAberto(false)} className='close-modal-cidade'>
                    <span className="material-symbols-outlined notranslate">&#xe5cd;</span> 
                  </button>
                  <h2>Escolha uma cidade</h2>
                  <img src={cidadeImg} alt='cidadeImg'/>
                  <ul>
                    {cidades.map((cidadeObj, index) => (
                      <li key={index} onClick={() => trocarCidade(cidadeObj.cidade)}>
                        <div className='li-div-cidade'>
                          <span className="material-symbols-outlined notranslate" style={{ fontSize: '18px' }}>&#xe0c8;</span> 
                          {cidadeObj.cidade}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div className='input-div'>
              <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
              <Link className='link-busca' to="/busca" state={{cidadeSelecionada}}>
                <input type='text' className='search-bar' placeholder='Pesquise comerciantes por nome ou serviço '/>
              </Link>
            </div>

            <div className='categorias-title'>
              <label>Categorias</label>
            </div>
            <div className='categorias'>
              <div>
                <Link to='/categorias/comércio' state={{cidadeSelecionada}}>
                  <span className="material-symbols-outlined notranslate notranslate" >&#xe8d1;</span>
                </Link>
                <h1>Comércios</h1>
              </div>
              <div>
                <Link to='/categorias/prestador de serviço' state={{cidadeSelecionada}}>
                  <span className="material-symbols-outlined notranslate notranslate" >&#xea3c;</span>
                </Link>
                <h1>Serviços</h1>
              </div>
              <div>
                <Link to='/categorias/imóvel' state={{cidadeSelecionada}}>
                  <span className="material-symbols-outlined notranslate notranslate">&#xe73a;</span>
                </Link>
                <h1>Imóveis</h1>
              </div>
            </div>

            <div className='comerciantes-title'>
              <label>Geral</label>
            </div>    
            <div className='comerciantes'>
              {comerciantes.map(comerciante => (
                <Link to="/comerciantedetails" state={{ comerciante, cidadeSelecionada }} key={comerciante.id}>
                  <div className='card'>
                    <div className='profile-image-div'>
                      <img className='profile-image' src={comerciante.profileImage || defaultImg} alt='profile-image'/>
                    </div>
                    <p className='notranslate'>{comerciante.nome || 'Nome Desconhecido'}</p>
                    <div>
                      <h1>{comerciante.servico || 'Serviço Desconhecido'}</h1>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className='baseboard'>
            <button id='selected'>
              <Link>
                <span className="material-symbols-outlined notranslate">&#xe88a;</span>
                Início
              </Link> 
            </button>
            <button>
              <Link to="/busca" state={{cidadeSelecionada}}>
                <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
                Busca
              </Link> 
            </button>
          </div>
        </>
      ) : (
        <>
        <div className='header-desktop'>
          <div className='content'>
            <img src={logoAlternativo} alt='logoalternativo'/>
            <div className='header-categoria-desktop'>
              <div className='categorias-title'>
                <label>Categorias</label>
                <span className="material-symbols-outlined notranslate">&#xe5c5;</span>
              </div>
              <div className='categorias-dropdown'>
                <div>
                  <Link to='/categorias/comércio' state={{cidadeSelecionada}}>
                    <div>
                      <span className="material-symbols-outlined notranslate">&#xe8d1;</span>
                      <h1>Comércios</h1>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to='/categorias/prestador de serviço' state={{cidadeSelecionada}}>
                    <div>
                      <span className="material-symbols-outlined notranslate">&#xea3c;</span>
                      <h1>Serviços</h1>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to='/categorias/imóvel' state={{cidadeSelecionada}}>
                    <div>
                      <span className="material-symbols-outlined notranslate">&#xe73a;</span>
                      <h1>Imóveis</h1>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='input-div'>
              <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
              <input 
                type='text' 
                className='search-bar' 
                placeholder='Procurando por alguma loja ou serviço?' 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} 
              />
            </div>
            <Link to="/login">
              <button className='login-button-desktop'>
                Entrar
                <span className="material-symbols-outlined notranslate">&#xea77;</span>
              </button>
            </Link> 
          </div>
        </div>
        <div className='catalog-desktop'>
          <div className='cidade'>
            <button className='cidade-button' onClick={() => setModalAberto(true)}>
              <span className="material-symbols-outlined notranslate" style={{ fontSize: '18px' }}>&#xe0c8;</span> 
              <p>{cidadeSelecionada}</p>
              alterar
              <span className="material-symbols-outlined notranslate" style={{ fontSize: '16px' }}>&#xe3c9;</span>
            </button>
          </div>
          {modalAberto && (
            <div className='modal-cidade'>
              <div className='modal-content-cidade'>
                <button onClick={() => setModalAberto(false)} className='close-modal-cidade'>
                  <span className="material-symbols-outlined notranslate">&#xe5cd;</span> 
                </button>
                <h2>Escolha uma cidade</h2>
                <img src={cidadeImg} alt='cidadeImg'/>
                <ul>
                  {cidades.map((cidadeObj, index) => (
                    <li key={index} onClick={() => trocarCidade(cidadeObj.cidade)}>
                      <div className='li-div-cidade'>
                        <span className="material-symbols-outlined notranslate" style={{ fontSize: '18px' }}>&#xe0c8;</span> 
                        {cidadeObj.cidade}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className='destaques-title'>
            <label>Lojas, prestadores de serviço e imóveis</label>
          </div>
          <div className='destaques'>
            {filteredComerciantes.map(comerciante => (
              <div className='card-destaque' onClick={() => openModalComercianteDetails(comerciante)}>
                <div className='profile-image-div'>
                  <img className='profile-image' src={comerciante.profileImage || defaultImg} alt='profile-image'/>
                </div>
                <div className='destaque-infos'>
                  <p className='nome'>{comerciante.nome || 'Nome Desconhecido'}</p>
                  <div>
                    <span className="material-symbols-outlined notranslate">
                      {comerciante.tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                      comerciante.tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                      comerciante.tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)}
                    </span>
                    <p>{comerciante.tipo}</p>
                  </div>
                  <h1>{comerciante.servico || 'Serviço Desconhecido'}</h1>
                </div>
              </div>
            ))}
          </div>
        </div>
        {modalComercianteDetails && selectedComerciante && (
          <div className="modal-comercianteDetails" onClick={closeModalComercianteDetails}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <ComercianteDetails
                comerciante={selectedComerciante}
                cidadeSelecionada={cidadeSelecionada}
                onClose={closeModalComercianteDetails}
              />
            </div>
          </div>
        )}
            
        <div className='contato'>
          <p>Quer aparecer aqui no <p className='vitrinne'>Vitrinne</p>?</p>
          <p>Entre em contato conosco através das redes abaixo ou <p className='click-contato' onClick={() => abrirWhatsApp('88988867417')}>Clique aqui!</p></p>
        </div>

        <div className='baseboard-desktop'>
          <div className='left'>
            <img src={baseboardLogo} alt='logo'/>
          </div>
          <div className='right'>
            <div className='baseboard-socials'>
              <div>
                <img src={instagram} alt='instagram' />
                <a href='https://www.instagram.com/vitrinnenet/'>@vitrinnenet</a>
              </div>
              <div>
                <img src={Whatsapp} alt='whatsapp' />
                <p onClick={() => abrirWhatsApp('88988867417')}>+55 (88) 9 8886-7417</p>
              </div>
              <div>
                <img src={gmail} alt='gmail' />
                <p>vitrinne.nordeste@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className='dev'>2024<span class="material-symbols-outlined notranslate">&#xe90c;</span>Dev. Jonas-Gomes</div>
        </>
      )}

    </div>
  ); 
}
