import React, {useEffect} from 'react';
import './style.css';
import logo from '../../images/splashScreenLogo.png';
function SplashScreen() {

  useEffect(() => {
    localStorage.removeItem('cidadeSelecionada');
    localStorage.setItem('primeiroAcesso', 'true');
  }, []);
  return (
    <div className="splash-screen">
      <img src={logo} alt='logo' />
      <div className="splashSpinner"></div>
    </div>
  );
}

export default SplashScreen;
